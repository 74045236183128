<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from "../../../helpers/fakebackend/auth-header";

//import { required } from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Configure Access Control",
    meta: [
      {
        name: "Configure Access Control",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Configure Access Control",
      status: "",
      submitted: false,
      employeeTypes: [],
      itemTitle: "",
      typeID: "",
      price: "",
      showDismissibleAlert: false,
      loading: false,
      role: "",
      loginType: "",
      empTypeID: "",
      roleType: "",
      roleEntity: "",
      publicAccess: "",
      roleDetails: [],
      empData: "",
      loginTypeArr: [
        { name: "CloudKitch Admin", id: 1 },
        { name: "Restaurant", id: 2 },
        { name: "Corporate", id: 3 },
        { name: "Principle", id: 4 },
      ],
    };
  },

  methods: {
    submitForm() {
      this.submitted = true;
      // stop here if form is invalid

      this.loading = true;
      let formData = new FormData();

      formData.append("roleDetails", JSON.stringify(this.roleDetails));
      formData.append("empTypeID", this.empTypeID);
      formData.append("createdBy", this.$storageData.profile.pid);
      formData.append("loginTypeID", this.$storageData.login_type);

      let apiName = "updateEmpRoleAccess";

      this.axios
        .post(apiName, formData, {
          headers: authHeader(),
        })
        .then((result) => {
          this.status = result.data.data;
          //this.$router.go();

          this.showDismissibleAlert = true;
          this.loading = false;
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(function (error) {
          this.loading = false;
          console.error(error.response);
          alert("Something went Wrong");
        });
    },

    getConfigureAccessControlData() {
      this.axios
        .post(this.$loggedRole+"/getConfigureAccessControlData", { empTypeID: this.empTypeID })
        .then((result) => {
          this.roleDetails = result.data.data;
          this.empData = result.data.empRole;
        });
    },
  },

  mounted() {
    if (this.$route.params.empTypeID) {
      // from userAccessControl page
      sessionStorage.setItem("empTypeID", this.$route.params.empTypeID);
    }
    this.empTypeID = sessionStorage.getItem("empTypeID");
    this.getConfigureAccessControlData();
  },

  middleware: "authentication",
};
</script>

<template lang="en">
  <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
          <b-alert v-model="showDismissibleAlert"  variant="success" dismissible>
            Role access updated Successfully!
          </b-alert>
      </div>
    
      <div class="card">
          <div class="card-body">
              <div class="row">
                <div class="col-md-3 col-lg-2">
                  <span>Role</span>
                  <h5 class="mb-3">{{empData.role}}</h5>       
                </div>
                <div class="col-md-3 col-lg-2">
                  <span>Role Type</span>
                  <h5 class="mb-3">{{ empData.roleType }}</h5>       
                </div>
                <div class="col-md-3 col-lg-2" v-if="empData.entity != '' && empData.entity != null">
                  <span>Entity</span>
                  <h5 class="mb-3">{{ empData.entity }}</h5>
                </div>
                <div class="col-md-3 col-lg-2">
                  <span>Public Access</span>
                  <h5 class="mb-3">{{ empData.publicAccess }}</h5>
                </div>
              </div>
              <div class="table-responsive">
                  <table class="table mb-0" style="max-width: 800px;">
                      <tbody>
                          <tr style="font-weight:700;">
                              <td class="td"> </td>
                              <td class="td text-center width100"> View </td>
                              <td class="td text-center width100"> Add </td>
                              <td class="td text-center width100"> Edit </td>
                              <td class="td text-center width100"> Delete </td>
                          </tr>                         
                          <template v-for="j in roleDetails">
                              <tr>
                                  <td class="td" style="font-weight: 700; background: #ebebeb;">
                                      {{j.displayTitle}}<br>
                                      <span style="font-weight:300;">{{j.description}}</span>
                                  </td>                                            
                                  <td class="td text-center vertical-middle" style="background: #ebebeb;">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="j.showViewCheckbox" title="View" true-value=1 false-value=0 :value="j.isView" v-model="j.isView">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td>  
                                  <td class="td text-center vertical-middle" style="background: #ebebeb;">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="j.showAddCheckbox" title="Add" true-value=1 false-value=0 :value="j.isAdd" v-model="j.isAdd">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td>  
                                  <td class="td text-center vertical-middle" style="background: #ebebeb;">                                          
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="j.showEditCheckbox" title="Edit" true-value=1 false-value=0 :value="j.isEdit" v-model="j.isEdit">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td> 
                                  <td class="td text-center vertical-middle" style="background: #ebebeb;">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="j.showDeleteCheckbox" title="Delete" true-value=1 false-value=0 :value="j.isDelete" v-model="j.isDelete">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td> 
                              </tr>
                              <tr v-if="j.childMenuArr.length>0" v-for="i in j.childMenuArr" >
                                  <td class="td" style="padding-left:25px !important">{{i.displayTitle}}<br><span style="font-weight:300;">{{i.description}}</span></td>
                                  <!-- <div style="padding-left:25px !important">{{i.description}}</div> -->
                                  <td class="td text-center vertical-middle">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="i.showViewCheckbox" title="View" true-value=1 false-value=0 :value="i.isView" v-model="i.isView" :disabled="j.isView==0">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td>  
                                  <td class="td text-center vertical-middle">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="i.showAddCheckbox" title="Add" true-value=1 false-value=0 :value="i.isAdd" v-model="i.isAdd" :disabled="j.isView==0">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td>  
                                  <td class="td text-center vertical-middle">                                    
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="i.showEditCheckbox" title="Edit" true-value=1 false-value=0 :value="i.isEdit" v-model="i.isEdit" :disabled="j.isView==0">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td> 
                                  <td class="td text-center vertical-middle">
                                      <label class="form-checkbox">
                                          <input type="checkbox" class="form-check-input" v-show="i.showDeleteCheckbox" title="Delete" true-value=1 false-value=0 :value="i.isDelete" v-model="i.isDelete" :disabled="j.isView==0">
                                          <i class="form-icon"></i>
                                      </label>
                                  </td> 
                              </tr>
                          </template>
                      </tbody>
                  </table>
              </div>
              <div class ="row" style="float:right;">
                  <button type="button" @click="submitForm()" class="float" :disabled="loading" title="Save">
                    <div v-if="loading">
                      <b-spinner class="m-2 col-3" variant="primary" role="status"></b-spinner>
                    </div>
                    <div v-else>
                      <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                    </div>
                  </button>
              </div>
          </div>
      </div>
  </Layout>
</template>

<style lang="scss" scoped>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
.table.b-table > thead > tr > th {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 1px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext {
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}

.float {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 40px;
  right: 40px;
  background-color: #ffc60c;
  color: #212121;
  border-radius: 50%;
  border-color: #00000000;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 22px;
}

.td {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}
.width100 {
  width: 100px !important;
}

.vertical-middle {
  vertical-align: middle;
}
</style>

